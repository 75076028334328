import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/tr';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import FetchBackend from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';
dayjs.locale(localStorage.getItem('i18nextLng') || 'en');
i18n
  .use(FetchBackend) //Allow loading using fetch
  .use(LanguageDetector) //Detect language from browser
  .use(initReactI18next) //Use React components
  .init({
    fallbackLng: 'en',
    debug: false,
    ns: ['core', 'retail',"auth","knowledgeBase","checklistForms","floorManagement","survey","dictionary"],
    defaultNS: 'retail',
    supportedLngs: ['en', 'dev', 'tr', 'nl', 'fr', 'es', 'de', 'it'],
    fallbackNS: ['core', 'retail'],
    whitelist: ['en', 'dev', 'tr', 'fr', 'nl', 'es', 'de', 'it'],
    interpolation: {
      escapeValue: false, //Already handled by React
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json?t=' + __I18N_URL__,
    },

    react: {
      useSuspense: false,
      wait: true,
      nsMode: 'fallback',
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
