import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('core');

  const { enqueueSnackbar } = useSnackbar();

  const countries = [
    { i18n: 'TR'.toLowerCase(), code: 'TR', label: 'Turkish' },
    { i18n: 'EN'.toLowerCase(), code: 'US', label: 'English' },
    { i18n: 'FR'.toLowerCase(), code: 'FR', label: 'France' },
    { i18n: 'NL'.toLowerCase(), code: 'NL', label: 'Dutch' },
    // { i18n: 'ES'.toLowerCase(), code: 'ES', label: 'Spain' },
    // { i18n: 'IT'.toLowerCase(), code: 'IT', label: 'Italian' },
    // { i18n: 'DE'.toLowerCase(), code: 'DE', label: 'German' },
  ].sort((a, b) => a.label.localeCompare(b.label));

  const currLang = localStorage.getItem('i18nextLng') || 'en';
  const value = countries.find((country) => country.i18n === currLang);

  const handleChange = (event, value, reason) => {
    const currentLocale = dayjs.locale();
    dayjs.locale(value.i18n);
    console.log("value.i18n",value.i18n)
    i18n
      .changeLanguage(value.i18n)
      .then(() => {
        enqueueSnackbar(t('navigation.change-language-success'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(t('navigation.change-language-error'), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        dayjs.locale(currentLocale);
      });
  };
  return (
    <Autocomplete
      options={countries}
      autoComplete={false}
      filterOptions={(options, params) => options}
      value={value}
      disableClearable
      onChange={handleChange}
      size="small"
      style={{ width: '100%', minWidth: 160 }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography variant="body1">
            {countryToFlag(option.code)} {option.label}
          </Typography>
        </li>
      )}
      renderInput={(params) => {
        return (
          <TextField {...params} label={t('navigation.change-language')} />
        );
      }}
    />
  );
};

export default ChangeLanguage;
