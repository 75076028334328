/**
 * Supplies the core react-router configuration.
 * Add additional routes and pages here and within this folder.
 */
import { Layout, Spin } from 'antd';
// *** Page Layout ***
import Sidebar from 'Core/components/page-menu';
import React, {
  useEffect,
  Component,
  useLayoutEffect,
  useState,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Outlet,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom';
import BootstrapOverlay from '../components/bootstrap-overlay';
import { Bootstrap } from '../state/actions/bootstrap';
import Logout from './logout';
// import "../../";
// *** Import important static routes ***
import {
  useUserController,
  userCheckLogin,
  userLoggedIn,
} from '@maple-tech/auth';
import AuthPagesRoutes from '@maple-tech/auth-web-app';
import { CssBaseline, ThemeProvider } from '@mui/material';
import DefaultTheme from 'Core/Themes/Default';
import useRetailRoutes from 'Retail/routes';
import { getAnalytics, logEvent } from 'firebase/analytics';
import MapleLoginPage from './login';
import { firebaseAnalytics } from '../FCM';
import { fetchUser } from '../../retail/state/actions/retail';
import SupportInfoDialog from 'Core/components/support-info-dialog';

const LoadingPage = () => (
  <div
    style={{
      display: 'flex',
      height: '100vh',
      width: '100vw',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    }}
  >
    <Spin tip="Loading Page.." />
  </div>
);

const DashboardLayer = () => {
  const state = useLocation();
  return (
    <Layout>
      <Sidebar currentPath={state.pathname} />
      <Layout style={{ overflow: 'auto', height: '100vh', maxHeight: '100vh' }}>
        <Layout.Content>
          <Outlet />
        </Layout.Content>
        <InfoDialog />
      </Layout>
    </Layout>
  );
};

const InfoDialog = () => {
  const [open, setOpen] = useState(
    localStorage.getItem('supportInfoDialogChecked') !== 'true'
  );
  const closeDialog = useCallback(() => {
    localStorage.setItem('supportInfoDialogChecked', true);
    setOpen(false);
  }, []);
  return <SupportInfoDialog open={open} onClose={closeDialog} />;
};

// const LogInOutRoutes = createBrowserRouter([
//   {
//     path: "login",
//     element: <Login />,
//   },
//   {
//     path: "logout",
//     element: <Logout />,
//   },
//   {
//     path: "register",
//     element: <Register />,
//   },
//   {
//     path: "forgot-password",
//     element: <ForgotPassword />,
//   },
//   {
//     path: "*",
//     element: <NotFound />,
//   },
// ]);
const RedirectLoginPage = () => {
  const navigate = useNavigate();
  useLayoutEffect(() => {
    navigate('/login');
  }, []);
  return null;
};
const LoginFlowRoutes = () => {
  // custom uuid for dev => 0x86Bd77318bA4524F8Ad22A1E2897A27A53a99561
  let mapleDevCheck = localStorage.getItem('maple-dev-check');

  // if user is not logged in yet, save the current path to redirect after login
  const path = window.location.pathname;
  const search = window.location.search;
  if (
    path !== '/' &&
    path !== '/login' &&
    path !== '/logout' &&
    path !== '/register' &&
    path !== '/forgot-password'
  ) {
    localStorage.setItem('searchParams', search);
    localStorage.setItem('redirect', path);
  }
  // !TODO , move to the auth-web-app
  // this is for directly accessing the maple web app from odin with
  // secure login. This needs to be happen in auth-web-app
  // but implemented here for temporarily
  // let odinLogin = localStorage.getItem("odin-login");
  const { setUser } = useUserController();
  const params = new URLSearchParams(window.location.search);
  const odin = params.get('odin');
  const email = params.get('email');
  if (odin && email) {
    localStorage.setItem('secureCode', odin);
    userLoggedIn(email, 'odin', undefined).then((user) => {
      // @ts-ignore
      if (user?.emailValidate) {
        console.log('Please validate your email address');
        return;
      }
      console.log(user);
      if (typeof user === 'object' && !!user) {
        console.log('Welcome');
        setUser(user.user);
        navigate('/');
      }
      // @ts-ignore
      if (user?.failed || !user) {
        console.log('Invalid Credentials');
        return;
      }
      console.log('An error occurred. Please try again later');
    });
    return <div>Waiting for odin login</div>;
  }
  // !TODO , move to the auth-web-app
  // localStorage.setItem("maple-dev-check", "0x86Bd77318bA4524F8Ad22A1E2897A27A53a99561");
  return useRoutes([
    {
      path: '/',
      element: <RedirectLoginPage />,
    },
    {
      path: '*',
      element: <LoadingPage />,
    },

    mapleDevCheck === '0x86Bd77318bA4524F8Ad22A1E2897A27A53a99561'
      ? { path: '/login', element: <MapleLoginPage /> }
      : AuthPagesRoutes,
  ]);
};
// const DashboardRoutes = getRetailRoutes();
const DashboardRoutes = () => {
  const { routes } = useRetailRoutes();

  return useRoutes([
    {
      path: '/',
      element: <AuthChecker />,
      children: routes,
    },
    {
      path: '/logout',
      element: <Logout />,
    },
  ]);
};

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (!firebaseAnalytics) return;
    logEvent(firebaseAnalytics, 'screen_view', {
      firebase_screen: location.pathname, // <- In my case I do not want to include search params, so 'location.pathname' is just what I want
      firebase_screen_class: 'firebase-routes-analytics', // <- This name is up to you
    });
  }, [location]);
};

const RouteList = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const userState = useUserController();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useAnalytics();
  useLayoutEffect(() => {
    if (!window.location.pathname.includes('/login')) {
      dispatch(fetchUser());
      userCheckLogin()
        .then((res) => {
          if (window.location.pathname.includes('/login')) navigate('/');
          setIsLoggedIn(true);
        })
        .catch((err) => {
          if (!window.location.pathname.includes('/login')) navigate('/login');
          setIsLoggedIn(false);
        });
    }
    // if (userState.isLoggedIn) {
    //   navigate('/survey')
    // } else {
    //   navigate('/login')
    // }
  }, []);
  useLayoutEffect(() => {
    console.log('Check User State', userState);
    if (userState.isLoggedIn === false) {
      setIsLoggedIn(false);
      // if (!window.location.pathname.includes("/login")) navigate("/login");
    } else if (window.location.pathname.includes('/login')) {
      setIsLoggedIn(true);
      // navigate("/");
    }
  }, [userState.isLoggedIn]);
  if (isLoggedIn === null) return <h4>Wait For Auth Control</h4>;
  if (userState.isLoggedIn) {
    return <DashboardRoutes />;
  }
  return <LoginFlowRoutes />;
};

const AuthChecker = () => {
  const [isControlled, setIsControlled] = useState(false);
  const dispatcher = useDispatch();
  const state = useSelector((state) => state);
  const userState = useUserController();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatcher(Bootstrap());

    userCheckLogin()
      .then(() => {
        // dispatcher(fetchUser());
        setIsControlled(true);
        if (window.location.pathname === '/login') navigate('/');
      })
      .catch((err) => {
        setIsControlled(true);
        navigate('/login');
      });
  }, []);

  if (!isControlled) return <BootstrapOverlay fade={false} />;
  if (userState.isLoggedIn)
    return (
      <DashboardLayer>
        <Outlet />
      </DashboardLayer>
    );
  return <Outlet />;
};

// const mainRouter = createBrowserRouter(
//   [
//     {
//       path: "/",
//       element: <AuthChecker />,
//       children: DashboardRoutes,
//     },
//     {
//       path: "login",
//       element: <Login />,
//     },
//     {
//       path: "logout",
//       element: <Logout />,
//     },
//     {
//       path: "register",
//       element: <Register />,
//     },
//     {
//       path: "forgot-password",
//       element: <ForgotPassword />,
//     },
//     {
//       path: "*",
//       element: <NotFound />,
//     },
//   ],
//   {
//     basename: "/",
//   }
// );

//Export the default browser router so it can use the redux wrapped RouteBuilder
export default () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={DefaultTheme}>
        <CssBaseline />
        {/* <RouterProvider router={mainRouter} /> */}
        <BrowserRouter>
          <React.Suspense fallback={<LoadingPage />}>
            <RouteList />
          </React.Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
};
